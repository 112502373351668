// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import about from '../seo/about.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

const pageSchema = {
  title: 'Homepage',
  slug: '',
  abstract: about.text,
  breadcrumbs: [{ title: 'Cover Page', slug: '' }],
}

// // ----------------------------------------------------------------------------
// // --------------------------------------------------------------------- Images
// // ----------------------------------------------------------------------------
export const query = graphql`
  query {
    sa: file(relativePath: { eq: "sa.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    tm: file(relativePath: { eq: "tm.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper className="homepage" pageSchema={pageSchema} {...props}>
    <div className="lead">
      <h1 className="tc">Integral Yoga Talks</h1>
      <h2 className="tc">The Teachings of Sri Aurobindo and The Mother</h2>
    </div>
    <Row>
      <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
        <GatsbyImage
          image={getImage(props.data.tm)}
          style={{ height: '100%' }}
          objectPosition="top center"
        />
      </Col>
      <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
        <GatsbyImage
          image={getImage(props.data.sa)}
          style={{ height: '100%' }}
          objectPosition="top center"
        />
      </Col>
    </Row>
    <div className="about">
      <p className="tj talc">
        The teachings of Sri Aurobindo and Mother help and guide spiritual
        seekers all over the world.{' '}
        <span className="hidden-on-h-md hidden-on-h-sm hidden-on-w-md hidden-on-w-sm">
          Their greatness draws seekers of Truth to follow the path of yoga
          which they walked and made into a way for others to follow to attain
          greater consciousness. A sincere practitioner of Sri Aurobindo’s Yoga
          will find that, no matter how long it takes, it is a sure way to
          freedom and enlightenment.
        </span>{' '}
        This website offers a collection of talks on the yoga and work and lives
        of Sri Aurobindo and Mother, and books containing compilations of their
        writings and sayings. The talks are audios and videos of public
        lectures, and classes which have been broadcast on Auroville Radio over
        the past seven years. The books, published in Auroville, present
        teachings on the principle subjects taught by Sri Aurobindo and Mother.
      </p>
      <p className="as-nav tc">
        <Link to="/audio-talks">Audio Talks</Link>
        <span>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
        <Link to="/lectures">Lectures</Link>
        <span>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
        <Link to="/books">Books</Link>
        <span>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
        <Link to="/videos">Videos</Link>
        <span>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
        <Link to="/additional-materials">Additional Materials</Link>
        <span>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
        <Link to="/about">About</Link>
      </p>
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
